/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

var $ = window.$;
// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {
  function darkNav() {
    if ($(window).scrollTop() >= 100) {
      $("header").addClass("dark-header");
    } else {
      $("header").removeClass("dark-header");
    }
  }

  $(window).scroll(darkNav);

  $(document).ready(function () {
    $(".menu").on("click", function (e) {
      e.stopPropagation();
      $(".sidenav-wrapper").addClass("open");
    });

    function closeNav(e) {
      e.stopPropagation();
      $(".sidenav-wrapper").removeClass("open");
    }

    $(".close, .body-overlay").click(closeNav);

    $(".category-list").on("click", function (e) {
      $(".category-list").removeClass("selected");
      $(this).addClass("selected");
      $(".cat-all").addClass("d-none");
      if ($(".podcast-list-body .col-md-6").hasClass($(this).attr("id"))) {
        $(".podcast-list-body ." + $(this).attr("id")).removeClass("d-none");
      }
    });
  });
};
